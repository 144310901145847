/* line 16, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item {
	line-height: 1;
	position: relative;
}

/* line 20, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item .o-imagenav,
.c-listing-item .imagenav__caption {
	visibility: hidden;
	opacity: 0;
	display: block;
}

/* line 27, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item .imagenav__caption {
	padding: 10px;
	font-size: .85em;
	line-height: .85em;
}

/* line 35, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item:focus .o-imagenav, .c-listing-item:focus-within .o-imagenav {
	visibility: visible;
	opacity: 1;
}

/* line 42, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--highlight {
	transition: transform .5s ease-out;
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.6);
}

/* line 47, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item .c-tabs {
	z-index: 8;
}

/* line 53, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group-wrapper {
	padding-bottom: 15px;
}

/* line 62, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__feat-group-wrapper {
	padding-bottom: 20px;
	padding-top: 20px;
}

/* line 68, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group-wrapper .c-price .price__value {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 68, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat-group-wrapper .c-price .price__value {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 68, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat-group-wrapper .c-price .price__value {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 68, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat-group-wrapper .c-price .price__value {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 74, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group {
	text-transform: uppercase;
}

/* line 77, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group .listing-item__feat-value {
	font-weight: 700;
}

/* line 92, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__recently-sold {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	cursor: pointer;
}

/* line 99, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__recently-sold--position-fix {
	margin-top: 30px;
}

/* line 103, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__recently-sold--title {
	padding: 10px 20px 10px 20px;
	background: rgb(var(--color-global-title));
	color: rgb(var(--color-global-primary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .communityitem .listing-item__recently-sold--title {
	padding: 10px 20px 10px 20px;
}

/* line 110, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__recently-sold--content {
	background: rgb(var(--color-global-positive));
	min-height: 35px;
}

/* line 116, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__features-container__wrapper {
	position: static;
	display: flex;
	flex-wrap: wrap;
}

/* line 122, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__price-container:not(.listing-item__price-container-vr) {
	display: inline-block;
	width: 66.66%;
}

@media only screen and (max-width: 29.999em) {
	/* line 122, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.communityitem .listing-item__price-container:not(.listing-item__price-container-vr) {
		width: 100%;
		margin-top: 10px;
		text-align: left;
	}
}

/* line 133, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__price-container-vr {
	padding-top: 10px;
}

/* line 141, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__feat,
.map-info-bubble .listing-item__feat {
	font-size: 0.85em;
}

/* line 144, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__feat-value,
.map-info-bubble .listing-item__feat-value {
	text-transform: uppercase;
	font-weight: normal;
}

/* line 149, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__feat--range,
.map-info-bubble .listing-item__feat--range {
	text-transform: capitalize;
}

/* line 153, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.communityitem .listing-item__feat--alt,
.map-info-bubble .listing-item__feat--alt {
	font-size: 1em;
}

/* line 162, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble--alt .listing-item__feat {
	vertical-align: bottom;
}

/* line 165, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble--alt .listing-item__feat-value {
	font-weight: normal;
}

/* line 169, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble--alt .listing-item__feat-group {
	text-transform: capitalize;
}

/* line 176, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble .listing-item__feat {
	vertical-align: bottom;
}

/* line 179, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble .listing-item__feat--range {
	line-height: 1.375;
	vertical-align: bottom;
}

/* line 188, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group .listing-item__feat:before {
	content: "|";
	display: inline-block;
	padding: 0 5px;
}

/* line 194, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat-group .listing-item__feat:first-child:before {
	content: "";
	padding: 0;
}

/* line 201, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link {
	position: relative;
}

/* line 204, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link--shadow {
	box-shadow: 0 8px 10px -10px rgb(var(--color-global-negative));
}

/* line 210, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__action--over, .listing-item__link:hover .listing-item__actions, .listing-item__link:hover .listing-item__agent, .listing-item__link:hover .listing-item__brokerage {
	visibility: visible;
	opacity: 1;
}

/* line 219, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__agent-flyout {
	box-shadow: -5px 0 15px -10px rgb(var(--color-global-negative));
	background-color: rgb(var(--color-global-positive));
}

/* line 223, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__agent-flyout:hover {
	background-color: #709fb3;
}

/* line 228, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__agent-flyout-button {
	visibility: visible;
	opacity: 1;
}

/* line 233, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__text--bg {
	background-color: rgb(var(--color-global-widget2-1));
}

/* line 241, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery {
	margin-bottom: 10px;
}

/* line 244, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .price__upon-request {
	white-space: nowrap;
}

/* line 249, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .c-price--default .price__value,
.c-listing-item--gallery .c-price--default .price__currency {
	color: rgb(var(--color-global-helper));
}

/* line 267, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__link:hover .listing-item__feat__value {
	color: rgb(var(--color-global-text));
}

/* line 270, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__link:hover .listing-item__feat__value .icon {
	color: rgb(var(--color-global-negative));
}

/* line 275, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__link:hover .listing-item__feat__description {
	color: rgb(var(--color-global-text));
}

/* line 281, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__link:hover .c-price--default .price__value {
	color: rgb(var(--color-global-secondary));
}

/* line 285, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__link:hover .c-price--default .price__currency {
	color: rgb(var(--color-global-text));
}

/* line 291, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .box-expandable__content:not(.c-rental-availability) {
	background-color: rgb(var(--color-global-title));
	z-index: 9;
}

/* line 297, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__image-container {
	display: block;
	height: 100%;
}

/* line 303, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat--small {
	font-size: 1em;
	line-height: normal;
	text-align: center;
}

/* line 309, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat__value, .c-listing-item--gallery .listing-item__feat__description {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 309, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--gallery .listing-item__feat__value, .c-listing-item--gallery .listing-item__feat__description {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 309, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--gallery .listing-item__feat__value, .c-listing-item--gallery .listing-item__feat__description {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 309, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--gallery .listing-item__feat__value, .c-listing-item--gallery .listing-item__feat__description {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 319, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat__value::after, .c-listing-item--gallery .listing-item__feat__description::after {
	content: "\00a0";
}

/* line 324, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat__description {
	font-size: 0.9em;
}

/* line 329, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat-group:not(:first-child) {
	margin-top: 5px;
}

/* line 334, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat-group .price__value,
.c-listing-item--gallery .listing-item__feat-group .price__currency {
	line-height: inherit;
}

/* line 341, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat--inline {
	margin-right: 30px;
	margin-bottom: 300px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--gallery .listing-item__feat--inline {
	margin-right: initial;
	margin-left: 30px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--gallery .listing-item__feat--inline {
		margin-right: 0;
	}
}

/* line 346, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat--placeholder {
	margin-right: 0;
	height: 100%;
	vertical-align: middle;
	padding: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--gallery .listing-item__feat--placeholder {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--gallery .listing-item__feat--placeholder {
		margin-right: 0;
	}
}

/* line 353, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__feat .icon {
	margin: 0 0 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--gallery .listing-item__feat .icon {
	margin: 0 10px 0 0;
}

/* line 358, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box {
	background-color: rgb(var(--color-global-widget));
	padding: 10px;
	margin-top: 5px;
}

/* line 363, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--tables-holder {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	flex: 1;
}

/* line 370, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box-expandable-content {
	box-sizing: border-box;
	padding: 0;
}

/* line 375, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--openhouse, .c-listing-item--gallery .listing-item__box--pricehistory {
	padding: 9px 0;
	margin-top: 0;
}

/* line 383, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--openhouse dl, .c-listing-item--gallery .listing-item__box--pricehistory dl {
	padding: 5px 10px;
}

/* line 387, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--openhouse dl.is-expanded, .c-listing-item--gallery .listing-item__box--pricehistory dl.is-expanded {
	padding-bottom: 0;
}

/* line 394, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--openhouse {
	background: rgb(var(--color-global-widget));
}

/* line 397, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--openhouse .listing-item__table-head {
	background: rgb(var(--color-global-widget));
}

/* line 402, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--pricehistory {
	background-color: rgb(var(--color-global-widget2-10));
}

/* line 405, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--pricehistory .listing-item__table-head {
	background-color: rgb(var(--color-global-widget2-10));
}

/* line 410, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__box--no-bottom-gutter {
	padding-bottom: 0;
}

/* line 415, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__brokerage {
	padding: 0 20px 20px;
}

/* line 418, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__brokerage-logo {
	margin-right: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--gallery .listing-item__brokerage-logo {
	margin-right: initial;
	margin-left: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--gallery .listing-item__brokerage-logo {
		margin-right: 0;
	}
}

/* line 422, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__brokerage-name {
	padding-top: 10px;
	line-height: initial;
}

@media only screen and (max-width: 29.999em) {
	/* line 415, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--gallery .listing-item__brokerage {
		padding: 0 20px 10px;
	}
}

/* line 432, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__mls-id {
	display: block;
	margin-top: 10px;
}

/* line 441, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__bottom-details:not(.listing-item__bottom-details-vr):not(.listing-item__bottom-details--reversed) {
	display: table;
	height: 55px;
}

@media only screen and (max-width: 47.999em) {
	/* line 441, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__bottom-details:not(.listing-item__bottom-details-vr):not(.listing-item__bottom-details--reversed) {
		height: 40px;
	}
}

/* line 455, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__bottom-details {
	width: 100%;
	line-height: normal;
	box-sizing: border-box;
}

/* line 460, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__bottom-details--tools {
	margin-top: auto;
}

/* line 467, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble .listing-item__openhouse:not(.listing-item__openhouse--no-float) {
	padding: 5px 10px 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-info-bubble .listing-item__openhouse:not(.listing-item__openhouse--no-float) {
	padding: 5px 10px 0 10px;
}

/* line 472, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse.listing-item__feat--small {
	text-align: left;
	color: rgb(var(--color-global-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse.listing-item__feat--small {
	text-align: right;
}

/* line 477, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse::after {
	color: #709fb3;
}

/* line 481, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse + .box-expandable__content {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse + .box-expandable__content {
	text-align: right;
}

/* line 483, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse + .box-expandable__content .listing-item__block-title {
	visibility: hidden;
}

/* line 488, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-icon {
	font-size: 1.2em;
	padding-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse-icon {
	padding-left: initial;
	padding-right: 5px;
}

/* line 500, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-link {
	text-decoration: underline;
}

/* line 504, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-details {
	text-transform: uppercase;
}

/* line 508, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-status {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 180px;
}

@media only screen and (max-width: 59.999em) {
	/* line 508, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__openhouse-status {
		max-width: 100px;
	}
}

/* line 518, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-spacing {
	display: table-cell;
	padding: 0 20px 5px 0;
	line-height: 1.25;
}

/* line 523, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-spacing:last-child {
	padding-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse-spacing:last-child {
	padding-right: initial;
	padding-left: 0;
}

@media only screen and (max-width: 29.999em) {
	/* line 518, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__openhouse-spacing {
		padding-right: 15px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__openhouse-spacing {
		padding-right: initial;
		padding-left: 15px;
	}
}

/* line 534, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title {
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse-title {
	padding-left: initial;
	padding-right: 10px;
}

/* line 538, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-arrow {
	min-height: 0;
}

/* line 542, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-arrow.box-expander:after {
	z-index: 5;
	right: 0;
	top: 5px;
	border-top: 5px solid;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	transform: none;
}

/* line 552, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-arrow.box-expander.is-expanded:after {
	border-bottom: 5px solid;
}

/* line 559, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__features-container:not(.listing-item__features-container-vr) {
	display: block;
	position: relative;
	overflow: hidden;
}

/* line 566, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__features-container--right {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__features-container--right {
	text-align: left;
}

/* line 570, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__features-container__wrapper {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	overflow: hidden;
}

/* line 577, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__features-container-vr .listing-item__features-container__wrapper {
	left: 50%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__features-container-vr .listing-item__features-container__wrapper {
	left: auto;
	right: 50%;
}

/* line 581, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__price-container-vr--alt + .listing-item__features-container-vr .listing-item__features-container__wrapper {
	left: 70%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__price-container-vr--alt + .listing-item__features-container-vr .listing-item__features-container__wrapper {
	left: auto;
	right: 70%;
}

@media only screen and (max-width: 47.999em) {
	/* line 565, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__features-container {
		vertical-align: top;
	}
}

/* line 591, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 591, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 591, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 591, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 594, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--inline {
	margin-right: 30px;
	margin-bottom: 300px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__feat--inline {
	margin-right: initial;
	margin-left: 30px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__feat--inline {
		margin-right: 0;
	}
}

/* line 599, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--placeholder {
	height: 100%;
	vertical-align: middle;
	padding: 0;
	margin-left: 0;
	margin-right: 0;
}

/* line 608, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__price-container:not(.listing-item__price-container-vr) {
	text-align: left;
	display: block;
	width: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__price-container:not(.listing-item__price-container-vr) {
	text-align: right;
}

/* line 615, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__price-container .c-price {
	display: block;
	padding-right: 5px;
}

/* line 621, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__soldrent-date {
	white-space: nowrap;
}

@media only screen and (min-width: 48em) {
	/* line 628, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--list .listing-item__text--bg {
		margin-bottom: 20px;
	}
}

/* line 635, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__link:hover .o-imagenav,
.c-listing-item--list .listing-item__link:hover .imagenav__caption {
	visibility: visible;
	opacity: 1;
}

/* line 644, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list.c-listing-item--communities .listing-item__link:hover .o-imagenav,
.c-listing-item--list.c-listing-item--communities .listing-item__link:hover .imagenav__caption {
	visibility: visible;
	opacity: 1;
}

/* line 652, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .box-expandable__content .listing-item__block-title {
	display: none;
}

/* line 657, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__brokerage-logo .results-logo-wrapper {
	max-width: 60px;
	margin-top: 10px;
}

/* line 663, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__brokerage-name {
	color: rgb(var(--color-global-secondary));
	margin-top: 10px;
	display: block;
}

/* line 669, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .c-price:only-child,
.c-listing-item--list .c-price:last-child {
	margin-bottom: 20px;
}

/* line 674, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .c-price.is-expanded {
	display: inline-block;
}

/* line 678, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__openhouse-title,
.c-listing-item--list .listing-item__pricehistory-container {
	padding: 0;
}

/* line 685, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image {
	position: relative;
	background-color: rgb(var(--color-global-body));
}

/* line 689, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image--landscape {
	height: 215px;
	position: relative;
}

/* line 694, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image--square {
	height: 350px;
}

@media only screen and (max-width: 47.999em) {
	/* line 694, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__image--square {
		height: 280px;
	}
}

@media only screen and (min-width: 60em) and (max-width: 104.999em) {
	/* line 694, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__image--square {
		height: 310px;
	}
}

/* line 706, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image-overlay {
	transition: all .5s;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	overflow: hidden;
}

/* line 714, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image-overlay::before {
	background: rgba(0, 0, 0, 0.6);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
	content: "";
	position: absolute;
	top: 0;
	width: 100%;
	height: 30%;
	z-index: 7;
	visibility: hidden;
	opacity: 0;
	transition: all .5s;
}

/* line 729, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs-container {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
}

/* line 736, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs {
	margin-top: 15px;
	position: relative;
	visibility: hidden;
	opacity: 0;
	transition: all .5s;
}

/* line 744, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs .listing-item__action {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	margin-right: 20px;
	color: #fff;
	opacity: .7;
	cursor: pointer;
}

@media only screen and (max-width: 47.999em) {
	/* line 744, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tabs .listing-item__action {
		font-size: 1.6245em;
		line-height: 1.1525;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 744, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tabs .listing-item__action {
		font-size: 1.998em;
		line-height: 1.25;
	}
}

@media only screen and (min-width: 80em) {
	/* line 744, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tabs .listing-item__action {
		font-size: 2.25em;
		line-height: 1.25;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__tabs .listing-item__action {
	margin-right: initial;
	margin-left: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__tabs .listing-item__action {
		margin-right: 0;
	}
}

/* line 752, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs .listing-item__action:hover {
	opacity: 1;
}

/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	padding-left: 20px;
	padding-right: 20px;
	display: inline-block;
	opacity: .7;
	cursor: pointer;
}

@media only screen and (max-width: 47.999em) {
	/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tab {
		font-size: 1.6245em;
		line-height: 1.1525;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tab {
		font-size: 1.998em;
		line-height: 1.25;
	}
}

@media only screen and (min-width: 80em) {
	/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tab {
		font-size: 2.25em;
		line-height: 1.25;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tab {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (min-width: 60em) and (max-width: 104.999em) {
	/* line 758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__tab {
		padding-left: 15px;
		padding-right: 15px;
	}
}

/* line 778, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab.is-selected, .listing-item__tab:hover {
	opacity: 1;
}

/* line 783, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab .icon {
	color: #fff;
}

/* line 787, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab:only-child {
	display: none;
}

/* line 792, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab-content {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}

/* line 800, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab-content-contact {
	z-index: 999;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.6);
}

/* line 810, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab-content iframe {
	width: 100%;
	height: 100%;
}

/* line 819, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab-content .record-marker {
	cursor: default;
}

/* line 823, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tab-content .listing-item__mls-id {
	color: rgb(var(--color-global-positive));
}

/* line 828, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs-content {
	opacity: 0;
	z-index: 1;
	transition: opacity linear .3s;
}

/* line 833, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs-content.is-selected {
	opacity: 1;
	z-index: 2;
}

/* line 838, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__tabs-content.has-opacity {
	opacity: 1;
}

/* line 843, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mp4-container {
	height: 100%;
}

/* line 847, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-overlay,
.listing-item__tabs-content,
.listing-item__contact-info-container {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
}

/* line 856, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-overlay,
.listing-item__tabs-content {
	top: 0;
}

/* line 861, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-overlay {
	z-index: 999;
}

/* line 864, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.carousel--is-ios .has-youtube-video .listing-item__video-overlay {
	pointer-events: none;
}

/* line 869, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-play {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	color: #fff;
	opacity: 0;
	visibility: hidden;
	font-size: 4em;
}

/* line 878, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-play:hover {
	opacity: 1;
	cursor: pointer;
}

/* line 883, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__video-overlay + .listing-item__video-play {
	z-index: 999;
}

/* line 887, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.carousel--is-ios .has-youtube-video .listing-item__video-play {
	pointer-events: none;
}

/* line 890, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.carousel--is-ios .has-youtube-video .listing-item__video-play.icon-pause {
	visibility: hidden;
	opacity: 0;
}

/* line 897, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__contact-info-container {
	z-index: 999;
	pointer-events: none;
}

/* line 903, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.is-card-active .listing-item__image-overlay::before,
.is-card-active .listing-item__tabs,
.is-card-active .o-imagenav,
.is-card-active .imagenav__caption {
	visibility: visible;
	opacity: 1;
}

/* line 911, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.is-card-active .listing-item__video-play {
	z-index: 999;
	visibility: visible;
	opacity: .7;
}

/* line 920, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__image-container:hover .listing-item__flag {
	visibility: hidden;
	opacity: 0;
}

/* line 929, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc {
	padding: 0;
	display: flex;
	align-items: center;
	background-color: rgba(var(--color-global-negative), .5);
	height: 29px;
}

/* line 936, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse {
	width: 100%;
}

/* line 939, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse .listing-item__flag-title {
	flex: 1;
	display: flex;
	align-items: center;
}

/* line 944, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse .listing-item__flag-title .item {
	margin-right: 3px;
}

/* line 947, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse .listing-item__flag-title .item.dayliteral {
	text-transform: capitalize;
}

/* line 951, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse .listing-item__flag-title .item.can-wrap {
	flex: 0 1 auto;
}

/* line 955, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse .listing-item__flag-title .item.no-wrap {
	white-space: nowrap;
}

/* line 961, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc.with-openhouse:hover {
	background: rgb(var(--color-global-primary));
}

/* line 967, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title {
	padding: 10px 7px;
	font-weight: bold;
	line-height: 11px;
	font-size: 9px;
	cursor: pointer;
}

/* line 981, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title.box-expander {
	padding-right: 25px;
}

/* line 984, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title.box-expander::after {
	right: 10px;
}

/* line 989, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title.is-expanded {
	background: rgb(var(--color-global-primary));
}

/* line 994, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag--open-house-container {
	bottom: 100%;
	background-color: none;
	top: unset;
	box-sizing: border-box;
	line-height: 11px;
}

/* line 1002, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse {
	display: flex;
	padding-left: 20px;
	font-size: 1.1em;
	background: rgba(255, 255, 255, 0.9);
	color: #58585a;
	font-weight: 600;
	padding-right: 25px;
	align-items: center;
	min-height: 29px;
	height: 29px;
}

/* line 1021, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse:nth-child(odd) {
	background: rgba(238, 242, 248, 0.9);
}

/* line 1025, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .item {
	margin-right: 3px;
}

/* line 1028, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .item.dayliteral {
	text-transform: capitalize;
}

/* line 1032, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .item.can-wrap {
	flex: 0 1 auto;
}

/* line 1036, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .item.no-wrap {
	white-space: nowrap;
}

/* line 1041, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .listing-item__openhouse-icon {
	padding-left: 0;
	color: #58585a;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__flag-title--openhouse .listing-item__openhouse-icon {
	padding-left: initial;
	padding-right: 0;
}

/* line 1048, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__openhouse-icon {
	padding-left: 0;
	color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .listing-item__openhouse-icon {
	padding-left: initial;
	padding-right: 0;
}

/* line 1053, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container .listing-item__flag--nyc .flag-color {
	width: 15px;
	height: 100%;
}

/* line 1060, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__image-container:hover .listing-item__flag {
	visibility: visible;
	opacity: 1;
}

/* line 1068, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over:not(.u-ar__content) {
	position: relative;
	overflow: hidden;
}

/* line 1073, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--border {
	border: 1px solid rgb(var(--color-global-border));
	border-top: 0;
	display: block;
}

/* line 1080, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details-link {
	font-weight: 500;
	white-space: nowrap;
}

/* line 1085, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text {
	font-size: 14px;
	font-size: 1rem;
	display: block;
	line-height: 2;
	color: rgb(var(--color-global-text));
	font-weight: 400;
}

/* line 1092, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-tabs-data .listing-item__text,
.m-tabsdata .listing-item__text {
	line-height: 1;
}

/* line 1097, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--bg {
	background-color: rgb(var(--color-global-widget2));
	position: relative;
}

/* line 1102, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--flex {
	display: flex;
	flex-direction: column;
}

/* line 1107, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--spaced {
	display: block;
	padding: 10px 20px 0;
}

/* line 1111, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--gallery .listing-item__text--spaced {
	padding-bottom: 10px;
}

/* line 1116, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--spaced-half {
	display: block;
	padding: 10px 10px 0;
}

/* line 1121, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--over {
	position: absolute;
	bottom: 0;
	z-index: 3;
	width: 100%;
	box-sizing: border-box;
}

/* line 1129, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--over .listing-item__name, .listing-item__text--over .listing-item__address, .listing-item__text--over .listing-item__feat, .listing-item__text--over .listing-item__feat--price, .listing-item__text--over .listing-item__feat--currency {
	color: #fff;
}

/* line 1139, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--card-description {
	display: block;
	margin-top: 10px;
	padding-bottom: 10px;
	padding-top: 10px;
	border-top: 1px solid rgb(var(--color-global-border));
	line-height: 1.4em;
	color: rgb(var(--c-listing-item-text-color--20));
}

/* line 1148, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link:hover .listing-item__text--card-description {
	color: rgb(var(--color-global-highlight));
}

/* line 1153, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text--flexitem {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
}

/* line 1159, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text .comma {
	padding-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__text .comma {
	padding-right: initial;
	padding-left: 5px;
}

/* line 1163, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text p.listing-item__feat {
	text-align: justify;
}

/* line 1170, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__link--tools,
.listing-item__details--tools {
	display: flex;
	flex-direction: column;
}

/* line 1176, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__group {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
}

/* line 1182, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__container {
	padding-right: 25px;
	min-width: 0;
	position: relative;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__container {
	padding-right: initial;
	padding-left: 25px;
}

/* line 1192, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__name {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	display: block;
	color: rgb(var(--color-global-highlight));
}

@media only screen and (max-width: 47.999em) {
	/* line 1192, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__name {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1192, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__name {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1192, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__name {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 1204, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__text:hover .listing-item__name {
	color: #709fb3;
}

/* line 1209, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__name > span.buildingname {
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 1214, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__address {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	color: rgb(var(--color-global-text));
	display: block;
	line-height: 1.3;
}

@media only screen and (max-width: 47.999em) {
	/* line 1214, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__address {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1214, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__address {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1214, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__address {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 1227, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__address:hover {
	color: rgb(var(--color-global-text));
}

/* line 1238, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__select {
	left: 10px;
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	top: -2px;
	position: absolute;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__select {
	left: auto;
	right: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 1238, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__select {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1238, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__select {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1238, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__select {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 1244, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__container .listing-item__select {
	left: -10px;
}

/* line 1249, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__select-name {
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__select-name {
	padding-left: initial;
	padding-right: 10px;
}

/* line 1253, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-custom-checkbox--large + .listing-item__select-name,
.c-input-box--large + .listing-item__select-name {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-custom-checkbox--large + .listing-item__select-name, .is-rtl
.c-input-box--large + .listing-item__select-name {
	padding-left: initial;
	padding-right: 20px;
}

/* line 1258, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	font-style: normal;
	color: rgb(var(--color-global-secondary));
	flex: 0 0 50%;
}

@media only screen and (max-width: 47.999em) {
	/* line 1258, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1258, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1258, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 1267, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--inline {
	display: inline-block;
	vertical-align: middle;
}

/* line 1272, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--block {
	display: block;
}

/* line 1276, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--large {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 1276, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--large {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1276, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--large {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1276, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--large {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--price {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	color: rgb(var(--color-global-highlight));
	margin-bottom: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--price {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--price {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--price {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 1285, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--price:hover {
	color: rgb(var(--color-global-highlight));
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--price {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 1280, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__feat--price {
		margin-bottom: 0;
	}
}

/* line 1298, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--alt {
	color: rgb(var(--color-global-text));
}

/* line 1302, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--currency {
	color: rgb(var(--color-global-highlight));
}

/* line 1306, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat > dd:first-child:not(.listing-item__openhouse--no-float),
.listing-item__feat > dt:first-child {
	float: left;
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__feat > dd:first-child:not(.listing-item__openhouse--no-float), .is-rtl
.listing-item__feat > dt:first-child {
	float: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__feat > dd:first-child:not(.listing-item__openhouse--no-float), .is-rtl
.listing-item__feat > dt:first-child {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__feat > dd:first-child:not(.listing-item__openhouse--no-float), .is-rtl
	.listing-item__feat > dt:first-child {
		margin-right: 0;
	}
}

/* line 1316, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat > dd:first-child:not(.listing-item__openhouse--no-float):empty,
.listing-item__feat > dt:first-child:empty {
	display: none;
}

/* line 1322, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__feat.listing-item__openhouse > dd:first-child,
.c-listing-item--list .listing-item__feat.listing-item__openhouse > dt:first-child {
	clear: right;
	float: none;
	display: block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--list .listing-item__feat.listing-item__openhouse > dd:first-child, .is-rtl
.c-listing-item--list .listing-item__feat.listing-item__openhouse > dt:first-child {
	clear: left;
}

/* line 1329, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__feat.listing-item__openhouse .listing-item__openhouse-spacing {
	padding-right: 5px;
	display: inline-block;
	overflow: visible;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--list .listing-item__feat.listing-item__openhouse .listing-item__openhouse-spacing {
	padding-right: initial;
	padding-left: 5px;
}

/* line 1333, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__feat.listing-item__openhouse .listing-item__openhouse-spacing:last-child {
	padding-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--list .listing-item__feat.listing-item__openhouse .listing-item__openhouse-spacing:last-child {
	padding-right: initial;
	padding-left: 0;
}

/* line 1339, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat > dd {
	color: rgb(var(--color-global-negative));
}

/* line 1346, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat--units-available {
	font-weight: 700;
}

/* line 1354, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__feat.listing-item__feat--text-case,
.listing-item__feat__description.listing-item__feat--text-case {
	text-transform: uppercase;
}

/* line 1361, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.community-item__text--fixed-height {
	height: 130px;
}

/* line 1366, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__actions {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	text-align: right;
}

@media only screen and (max-width: 47.999em) {
	/* line 1366, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions {
		font-size: 1.6245em;
		line-height: 1.1525;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1366, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions {
		font-size: 1.998em;
		line-height: 1.25;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1366, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions {
		font-size: 2.25em;
		line-height: 1.25;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__actions {
	text-align: left;
}

/* line 1370, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__actions--tools {
	display: flex;
}

/* line 1373, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__actions--tools .listing-item__action:not(:first-child) {
	margin-left: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__actions--tools .listing-item__action:not(:first-child) {
	margin-left: initial;
	margin-right: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__actions--tools .listing-item__action:not(:first-child) {
		margin-left: 0;
	}
}

/* line 1378, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__actions .listing-item__action {
	font-size: 0.85em;
}

@media only screen and (max-width: 47.999em) {
	/* line 1378, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions .listing-item__action {
		font-size: 0.85em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1378, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions .listing-item__action {
		font-size: 0.85em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1378, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions .listing-item__action {
		font-size: 0.85em;
	}
}

/* line 1382, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__actions--over {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	position: relative;
}

@media only screen and (max-width: 47.999em) {
	/* line 1382, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions--over {
		font-size: 1.6245em;
		line-height: 1.1525;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1382, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions--over {
		font-size: 1.998em;
		line-height: 1.25;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1382, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__actions--over {
		font-size: 2.25em;
		line-height: 1.25;
	}
}

/* line 1387, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__actions {
	position: absolute;
	z-index: 3;
}

/* line 1391, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__actions .listing-item__action {
	color: #fff;
	padding: 0 20px 10px;
}

/* line 1395, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__actions .listing-item__action:first-child {
	padding-top: 20px;
}

/* line 1402, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__action {
	color: #709fb3;
	display: block;
	font-style: normal;
	opacity: 0.75;
	transform: translateZ(0);
}

/* line 1410, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__action:hover {
	opacity: 1;
}

/* line 1414, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__action--over {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	z-index: 3;
	padding: 10px;
	color: #fff;
	transition: all .5s;
}

/* line 1426, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent {
	background-color: rgb(var(--color-global-widget));
	padding: 10px;
	line-height: 1.5;
}

/* line 1435, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__agent {
	background-color: transparent;
	display: inline-block;
}

/* line 1440, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-brokerage .listing-item__agent {
	position: relative;
}

/* line 1444, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent--with-photo {
	padding: 10px 20px;
}

/* line 1448, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-name {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	color: rgb(var(--color-global-highlight));
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 1448, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-name {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1448, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-name {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1448, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-name {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 1454, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-title {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	color: rgb(var(--color-global-highlight));
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 1454, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-title {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1454, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-title {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1454, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-title {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 1460, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-email {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 1460, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-email {
		font-size: 0.9em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1460, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-email {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1460, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-email {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

/* line 1464, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent--with-photo .listing-item__agent-email {
	display: inline-block;
	vertical-align: middle;
}

/* line 1469, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-email__address {
	color: rgb(var(--color-global-text));
	white-space: nowrap;
}

/* line 1476, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__contact-info-container .listing-item__agent-photo {
	margin-right: 10px;
	border-radius: 100%;
	display: inline-block;
	width: 60px;
	height: 60px;
	vertical-align: top;
	border: 2px solid rgb(var(--color-global-positive));
	overflow: hidden;
	background: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__contact-info-container .listing-item__agent-photo {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__contact-info-container .listing-item__agent-photo {
		margin-right: 0;
	}
}

/* line 1487, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__contact-info-container .listing-item__agent-photo .o-smartimage .smartimage__item {
	max-height: none;
	position: absolute;
}

@media only screen and (max-width: 29.999em) {
	/* line 1476, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__contact-info-container .listing-item__agent-photo {
		width: 45px;
		height: 45px;
	}
}

/* line 1500, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-details {
	display: inline-block;
	vertical-align: middle;
}

/* line 1504, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-photo + .listing-item__agent-details {
	max-width: calc(100% - 74px);
}

/* line 1509, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout {
	border-left: 1px solid rgb(var(--color-global-positive));
	right: 0;
	position: absolute;
	top: 0;
	width: 65px;
	height: 100%;
	white-space: nowrap;
	background-color: rgb(var(--color-global-widget2));
	transition: all .5s;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout {
	right: auto;
	left: 0;
}

/* line 1520, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .o-phone,
.listing-item__agent-flyout .o-phone-number,
.listing-item__agent-flyout .listing-item__agent-name,
.listing-item__agent-flyout .listing-item__agent-email {
	color: rgb(var(--color-global-positive));
}

/* line 1526, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .o-phone:hover,
.listing-item__agent-flyout .o-phone-number:hover,
.listing-item__agent-flyout .listing-item__agent-name:hover,
.listing-item__agent-flyout .listing-item__agent-email:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1531, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__agent-details,
.listing-item__agent-flyout .listing-item__brokerage-name {
	display: none;
}

/* line 1536, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__brokerage {
	text-align: left;
	padding: 10px;
	position: absolute;
	bottom: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout .listing-item__brokerage {
	text-align: right;
}

/* line 1542, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__brokerage-logo, .listing-item__agent-flyout .listing-item__brokerage-name {
	color: rgb(var(--color-global-positive));
}

/* line 1546, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__brokerage-logo:hover, .listing-item__agent-flyout .listing-item__brokerage-name:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1552, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__agent--with-photo {
	background-color: transparent;
	position: relative;
	padding: 10px;
}

/* line 1558, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout .listing-item__agent-photo {
	margin-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout .listing-item__agent-photo {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__agent-flyout .listing-item__agent-photo {
		margin-right: 0;
	}
}

/* line 1561, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble .listing-item__agent-flyout .listing-item__agent-photo {
	background-color: rgb(var(--color-global-positive));
}

/* line 1566, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-overlay {
	right: 100%;
	background-color: rgba(255, 255, 255, 0.4);
	position: absolute;
	height: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout-overlay {
	right: auto;
	left: 100%;
}

/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-open {
	width: 40%;
	background-color: #567bbf;
	overflow: visible;
}

/* line 1578, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-open .listing-item__agent-flyout-overlay {
	width: 100%;
}

@media only screen and (min-width: 80em) and (max-width: 104.999em) {
	/* line 1578, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open .listing-item__agent-flyout-overlay {
		width: 200%;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 1578, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open .listing-item__agent-flyout-overlay {
		width: 150%;
	}
}

/* line 1590, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-open .listing-item__agent-details,
.listing-item__agent-flyout-open .listing-item__brokerage-name {
	display: inline-block;
}

@media only screen and (min-width: 105em) and (max-width: 120em) {
	/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open {
		width: 39%;
	}
}

@media only screen and (min-width: 80em) and (max-width: 104.999em) {
	/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open {
		width: 27%;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open {
		width: 34%;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open {
		width: 46%;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 1573, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__agent-flyout-open {
		width: 62%;
	}
}

/* line 1616, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-button {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	right: calc(100% + 1px);
	background-color: #709fb3;
	padding: 9px 5px;
	position: absolute;
	top: calc(50% - 13px);
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	transition: all .5s;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout-button {
	border-top-left-radius: 0;
	border-top-right-radius: 3px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout-button {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 3px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout-button {
	right: auto;
	left: calc(100% + 1px);
}

/* line 1629, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-open .listing-item__agent-flyout-button {
	visibility: visible;
	opacity: 1;
}

/* line 1634, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-button__arrow {
	border-right: 5px solid rgb(var(--color-global-positive));
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	transition: all 1s;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-flyout-button__arrow {
	border-right: 0;
	border-left: 5px solid rgb(var(--color-global-positive));
}

/* line 1642, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-flyout-open .listing-item__agent-flyout-button__arrow {
	transform: rotate(180deg);
	visibility: visible;
	opacity: 1;
}

/* line 1651, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-list--with-photo {
	position: relative;
	padding: 40px 0 20px 0;
	box-sizing: border-box;
	margin-top: 30px;
}

/* line 1657, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-list--with-photo .listing-item__agent-photo {
	margin-right: 10px;
	border-radius: 100%;
	display: inline-block;
	width: 60px;
	height: 60px;
	border: 2px solid rgb(var(--color-global-positive));
	background: rgb(var(--color-global-positive));
	overflow: hidden;
	position: absolute;
	top: -30px;
	margin: 0 auto;
	left: 0;
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent-list--with-photo .listing-item__agent-photo {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__agent-list--with-photo .listing-item__agent-photo {
		margin-right: 0;
	}
}

/* line 1673, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-list--with-photo .listing-item__agent-photo .o-smartimage .smartimage__align-helper {
	margin-left: 0;
}

/* line 1677, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-list--with-photo .listing-item__agent-photo .o-smartimage .smartimage__item {
	max-height: none;
}

/* line 1684, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__agent {
	text-align: center;
}

/* line 1690, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .o-phone,
.listing-item__details--over .o-phone-number,
.listing-item__details--over .o-phone__text,
.listing-item__details--over .listing-item__agent-name,
.listing-item__details--over .listing-item__agent-title,
.listing-item__details--over .listing-item__agent-email {
	color: #fff;
	pointer-events: all;
}

/* line 1700, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .o-phone--emphased {
	font-weight: 500;
}

/* line 1705, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent--in-flyout {
	padding: 0;
}

/* line 1708, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__agent--in-flyout {
	position: absolute;
	visibility: visible;
	opacity: 1;
}

/* line 1714, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent--in-flyout .listing-item__agent-photo {
	margin-right: 5px;
	width: 60px;
	height: 80px;
	border-radius: 0;
	border-width: 0;
	overflow: hidden;
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__agent--in-flyout .listing-item__agent-photo {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__agent--in-flyout .listing-item__agent-photo {
		margin-right: 0;
	}
}

/* line 1723, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent--in-flyout .listing-item__agent-photo + .listing-item__agent-details {
	max-width: calc(100% - 65px);
}

/* line 1729, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-details .listing-item__agent-website {
	display: block;
}

/* line 1735, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__brokerage {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__details--over .listing-item__brokerage {
	text-align: right;
}

/* line 1738, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__brokerage .listing-item__brokerage-name {
	color: #fff;
	pointer-events: all;
}

/* line 1743, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__brokerage .listing-item__more-properties {
	margin: 0;
}

/* line 1746, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__brokerage .listing-item__more-properties a {
	color: #fff;
	line-height: normal;
	pointer-events: all;
}

/* line 1754, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-brokerage .listing-item__brokerage {
	position: relative;
}

/* line 1758, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__brokerage-logo {
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
}

/* line 1763, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__brokerage-logo .results-logo-wrapper {
	display: block;
	width: 100px;
	max-height: 100px;
}

@media only screen and (max-width: 29.999em) {
	/* line 1763, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__brokerage-logo .results-logo-wrapper {
		width: 40px;
		max-height: 40px;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 1763, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__brokerage-logo .results-logo-wrapper {
		width: 60px;
		max-height: 60px;
	}
}

/* line 1780, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__brokerage-name {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	display: inline-block;
	vertical-align: middle;
}

@media only screen and (max-width: 47.999em) {
	/* line 1780, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__brokerage-name {
		font-size: 0.9em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1780, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__brokerage-name {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1780, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__brokerage-name {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

/* line 1787, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__more-properties {
	font-size: 0.85em;
	background-color: rgb(var(--color-global-primary));
	padding: 10px;
	margin-top: 20px;
}

/* line 1793, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__more-properties a {
	color: rgb(var(--color-global-positive));
	line-height: normal;
}

/* line 1797, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__more-properties a:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1803, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__attribution {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	display: block;
	line-height: 1.3;
}

@media only screen and (max-width: 47.999em) {
	/* line 1803, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__attribution {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 1803, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__attribution {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 1803, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.listing-item__attribution {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 1808, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__attribution-text {
	font-size: 0.9em;
	font-style: italic;
	margin-top: 10px;
}

/* line 1825, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__cbsaname {
	order: 9;
}

/* line 1828, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__cbsaname .listing-item__feat {
	margin-top: 20px;
	padding-top: 10px;
	border-top: 1px solid rgb(var(--color-global-border));
}

/* line 1835, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-brokerage {
	position: absolute;
}

/* line 1839, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__separator {
	border-top: 1px solid rgb(var(--color-global-positive));
	width: 100%;
}

/* line 1844, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-photo-mask {
	clip-path: circle(40px at center);
	border: 2px solid rgb(var(--color-global-positive));
}

/* line 1850, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__flag {
	z-index: 3;
	visibility: visible;
	opacity: 1;
	transition: all .5s;
	bottom: 0;
}

/* line 1857, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item .listing-item__flag {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item .listing-item__flag {
	left: auto;
	right: 0;
}

/* line 1863, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__details--over .listing-item__agent-name,
.listing-item__details--over .listing-item__brokerage-name {
	text-transform: uppercase;
}

/* line 1871, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__agent-phone__item + .listing-item__agent-phone__item::before {
	content: ", ";
}

/* line 1877, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mls-details {
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px solid rgb(var(--color-global-border));
	height: auto;
}

/* line 1883, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.info-bubble__listing-wrap .listing-item__mls-details {
	margin: 10px;
}

/* line 1887, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mls-details .listing-item__brokerage-name {
	font-size: 1em;
	padding-top: 0;
}

/* line 1892, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mls-details .listing-item__brokerage-logo {
	margin-right: 0;
	text-align: right;
	display: block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__mls-details .listing-item__brokerage-logo {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__mls-details .listing-item__brokerage-logo {
		margin-right: 0;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__mls-details .listing-item__brokerage-logo {
	text-align: left;
}

/* line 1898, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mls-details .results-logo-wrapper {
	width: auto;
	height: 50px;
	display: inline-block;
}

/* line 1904, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__mls-details-container {
	padding: 0 20px 10px;
}

/* line 1907, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--list .listing-item__mls-details-container {
	text-align: center;
	padding: 0;
}

/* line 1914, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__address-block .listing-item__name,
.listing-item__address-block {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 1924, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.is-expanded + .listing-item__openhouse-details-wrapper {
	padding-left: 10px;
	box-sizing: border-box;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 4;
	box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.6);
	border: 1px solid rgb(var(--color-global-border));
	border-top: 0;
	background: rgb(var(--color-global-widget2));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-expanded + .listing-item__openhouse-details-wrapper {
	padding-left: initial;
	padding-right: 10px;
}

/* line 1937, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.map-info-bubble .is-expanded + .listing-item__openhouse-details-wrapper {
	padding-left: 20px;
	border: 0;
	background: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-info-bubble .is-expanded + .listing-item__openhouse-details-wrapper {
	padding-left: initial;
	padding-right: 20px;
}

/* line 1944, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.is-expanded + .listing-item__openhouse-details-wrapper .listing-item__openhouse-details {
	display: table-row;
}

/* line 1949, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-details {
	display: none;
	color: rgb(var(--color-global-negative));
}

/* line 1953, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-details:first-child,
.is-expanded + .listing-item__openhouse-details-wrapper .listing-item__openhouse-details {
	display: table-row;
}

/* line 1959, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__pricehistory-container {
	padding-left: 0;
	text-align: center;
	line-height: 1.2;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__pricehistory-container {
	padding-left: initial;
	padding-right: 0;
}

/* line 1971, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title .listing-item__table--small,
.listing-item__pricehistory-container .listing-item__table--small {
	font-size: 0.85em;
}

/* line 1975, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title.box-expander::after,
.listing-item__pricehistory-container.box-expander::after {
	right: 10px;
}

/* line 1979, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title .listing-item__pricehistory-title--feat, .listing-item__openhouse-title--feat,
.listing-item__pricehistory-container .listing-item__pricehistory-title--feat,
.listing-item__pricehistory-container--feat {
	padding: 0 5px 0 5px;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__openhouse-title .listing-item__pricehistory-title--feat, .is-rtl .listing-item__openhouse-title--feat, .is-rtl
.listing-item__pricehistory-container .listing-item__pricehistory-title--feat, .is-rtl
.listing-item__pricehistory-container--feat {
	padding: 0 5px 0 5px;
}

/* line 1986, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title-element,
.listing-item__openhouse-title .listing-item__openhouse-icon,
.listing-item__pricehistory-container-element,
.listing-item__pricehistory-container .listing-item__openhouse-icon {
	color: rgb(var(--color-global-primary));
}

/* line 1991, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-title .listing-item__pricehistory--ellipsis,
.listing-item__openhouse-title .listing-item__openhouse--ellipsis,
.listing-item__pricehistory-container .listing-item__pricehistory--ellipsis,
.listing-item__pricehistory-container .listing-item__openhouse--ellipsis {
	display: inline-block;
	max-width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: bottom;
}

/* line 1999, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__pricehistory-container.box-expander::after {
	position: relative;
	display: inline-block;
	right: -5px;
}

/* line 2005, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__pricehistory-container .listing-item__pricehistory-title-element {
	text-transform: capitalize;
}

/* line 2010, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-table,
.listing-item__pricehistory-table {
	margin-top: 5px;
	border-top: 1px solid rgb(var(--color-global-border));
}

/* line 2015, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listing-item__openhouse-table .listing-item__table--container::-webkit-scrollbar,
.listing-item__pricehistory-table .listing-item__table--container::-webkit-scrollbar {
	height: 10px;
}

/* line 2021, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details {
	padding: 0 20px 0 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details {
	padding: 0 20px 0 20px;
}

/* line 2024, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details.no-listing-attribution {
	padding-bottom: 10px;
}

/* line 2028, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container {
	display: block;
	padding-top: 10px;
}

/* line 2032, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container__wrapper {
	position: static;
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}

/* line 2039, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat {
	margin-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat {
		margin-right: 0;
	}
}

/* line 2046, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat:not(:last-child) {
	flex: 0;
}

/* line 2050, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat .icon {
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat .icon {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat .icon {
		margin-left: 0;
	}
}

/* line 2054, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat__value {
	white-space: nowrap;
	font-weight: 400;
	font-size: 1em;
}

/* line 2066, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat__description {
	font-size: 1em;
}

/* line 2075, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--inline {
	margin-bottom: 5px;
	max-width: 45px;
}

/* line 2083, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--inline:not(:first-child):before {
	content: "|";
	margin-left: 0;
	margin-right: 2px;
	vertical-align: top;
}

/* line 2091, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--text-case:last-of-type {
	flex-grow: 1;
	font-weight: 400;
}

/* line 2096, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--text-case:first-of-type {
	margin-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--text-case:first-of-type {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--text-case:first-of-type {
		margin-left: 0;
	}
}

/* line 2100, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--interiorsize {
	max-width: fit-content;
}

/* line 2103, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat--interiorsize .listing-item__feat__description {
	font-weight: 400;
}

/* line 2108, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__features-container .listing-item__feat:last-child {
	flex: 3;
	text-align: right;
}

/* line 2116, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container {
	flex-wrap: wrap;
}

/* line 2122, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .listing-item__soldrent-date {
	flex-basis: 100%;
	order: 2;
}

/* line 2127, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .price__value {
	display: inline;
}

/* line 2135, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .rental-availability__period {
	display: inline;
	padding-left: 5px;
	padding-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .rental-availability__period {
	padding-left: initial;
	padding-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .rental-availability__period {
	padding-right: initial;
	padding-left: 10px;
}

/* line 2140, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__price-container .rental-availability__period:after {
	right: 0;
}

/* line 2146, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat {
	flex: 0 0 auto;
	margin-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat {
		margin-right: 0;
	}
}

/* line 2150, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat .icon {
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat .icon {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat .icon {
		margin-left: 0;
	}
}

/* line 2154, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value {
	white-space: nowrap;
}

/* line 2158, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--inline {
	margin-bottom: 0;
}

/* line 2162, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/* line 2163, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value::after {
	display: none;
}

@media only screen and (max-width: 47.999em) {
	/* line 2162, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 2162, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 2162, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__value {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 2177, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__description {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/* line 2179, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__description::after {
	display: none;
}

@media only screen and (max-width: 47.999em) {
	/* line 2177, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__description {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 2177, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__description {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 2177, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat__description {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 2193, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:last-of-type {
	flex-grow: 1;
	margin-left: 20px;
	font-weight: 600;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:last-of-type {
	margin-left: initial;
	margin-right: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:last-of-type {
		margin-left: 0;
	}
}

/* line 2199, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:first-of-type {
	margin-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:first-of-type {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-listing-item--nyc .listing-item__bottom-details .listing-item__typeprice-container .listing-item__feat--text-case:first-of-type {
		margin-left: 0;
	}
}

/* line 2206, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools {
	display: flex;
	align-items: center;
	padding: 10px 10px 5px 20px;
	background: rgb(var(--color-global-positive));
	line-height: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__neighborhood-and-tools {
	padding: 10px 20px 5px 10px;
}

/* line 2213, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__neighborhood-container {
	flex: 8;
}

/* line 2216, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__neighborhood-container .listing-item__neighborhood {
	text-transform: uppercase;
	color: #b6bed6;
}

/* line 2235, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__action {
	text-align: right;
	padding-right: 10px;
	opacity: 0.5;
}

/* line 2244, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__action .icon {
	font-size: 1.5em;
	line-height: unset;
}

@media only screen and (max-width: 47.999em) {
	/* line 2244, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__action .icon {
		font-size: 1.5em;
	}
}

/* line 2254, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__select {
	text-align: right;
	position: static;
	padding-top: 2px;
}

@media only screen and (max-width: 47.999em) {
	/* line 2254, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__select {
		padding-top: 0;
	}
}

/* line 2263, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__select .input-box__item {
	padding-top: 0;
}

/* line 2267, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__neighborhood-and-tools .listing-item__select .input-box__icon {
	margin: 0;
	border-radius: 3px;
	background: #f7f7f7;
}

/* line 2275, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__text {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

/* line 2281, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__name {
	line-height: 1;
}

/* line 2288, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-tabsdata--community .c-listing-item--nyc .listing-item__name {
	line-height: 1.5;
}

/* line 2293, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__box--tables-holder {
	flex: 0;
}

/* line 2297, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container {
	display: flex;
	width: auto;
	align-items: center;
	margin-bottom: 5px;
}

/* line 2303, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container .price__upon-request {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 2311, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container .listing-item__price-container {
	flex: 1;
	display: flex;
	align-items: center;
}

/* line 2317, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container .listing-item__feat > dd {
	color: rgb(var(--color-global-secondary));
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.2em;
}

/* line 2328, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container .listing-item__mortgage {
	padding-left: 5px;
	font-size: 1em;
	color: #bbb;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__typeprice-container .listing-item__mortgage {
	padding-left: initial;
	padding-right: 5px;
}

/* line 2334, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__typeprice-container .listing-item__mortgage:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 2341, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__name--with-icon {
	display: inline-flex !important;
}

/* line 2344, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-agent-tabs-data .c-listing-item--nyc .listing-item__name--with-icon {
	margin-bottom: 5px;
}

/* line 2356, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__building-link {
	padding-right: 5px;
	color: rgb(var(--color-global-highlight));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__building-link {
	padding-right: initial;
	padding-left: 5px;
}

/* line 2360, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__building-link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 2365, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__address {
	display: flex;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: 1;
}

/* line 2372, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-agent-tabs-data .c-listing-item--nyc .listing-item__address {
	margin-bottom: 5px;
}

/* line 2375, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-agent-tabs-data .c-listing-item--nyc .listing-item__address .listing-item__address {
	margin-bottom: 0;
}

/* line 2381, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__features-container__wrapper, .c-listing-item--nyc .listing-item__typeprice-container {
	justify-content: space-between;
}

/* line 2385, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__features-container__wrapper .listing-item__priceperunit, .c-listing-item--nyc .listing-item__typeprice-container .listing-item__priceperunit {
	text-align: right;
}

/* line 2389, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__features-container__wrapper .listing-item__priceperunit .listing-item__feat__value:after, .c-listing-item--nyc .listing-item__typeprice-container .listing-item__priceperunit .listing-item__feat__value:after {
	content: normal;
}

/* line 2396, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__features-container__wrapper .listing-item__priceperunit .listing-item__feat__description:before, .c-listing-item--nyc .listing-item__typeprice-container .listing-item__priceperunit .listing-item__feat__description:before {
	content: "\2215";
}

/* line 2408, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__container {
	padding: 0 20px 5px 20px;
	background: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 2408, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.c-listing-item--nyc .listing-item__container {
		padding-top: 0;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-listing-item--nyc .listing-item__container {
	padding: 0 20px 5px 20px;
}

/* line 2416, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.m-tabsdata--community .c-listing-item--nyc .listing-item__container {
	padding: 10px 20px 10px 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-tabsdata--community .c-listing-item--nyc .listing-item__container {
	padding: 10px 20px 10px 20px;
}

/* line 2421, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__attribution {
	display: flex;
	margin-top: auto;
	justify-content: center;
}

/* line 2426, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.c-listing-item--nyc .listing-item__attribution-text {
	font-style: normal;
}

/* line 2432, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listings-results-container {
	z-index: initial !important;
}

/* line 2437, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.listings-results-container .listing-results-title-container .m-personalization--basic {
	position: absolute;
}

/* line 2443, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.resultssort-container {
	background-color: #f3f3f3;
}

/* line 2446, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.resultssort-container .grid__item:empty {
	display: inline-block;
}

/* line 2451, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.resultssort-container .m-results-sort .selectboxit-btn {
	border-radius: 3px;
	box-sizing: border-box;
	height: 29px;
}

/* line 2461, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.resultssort-container .m-results-sort .selectboxit-options {
	border-radius: 0 0 3px 3px;
}

/* line 2465, scss/80-themes/Saunders/60-components/listing-item-base.scss */
.resultssort-container .m-results-sort .selectboxit-container span,
.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options a,
.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options button {
	height: 100%;
	line-height: 29px;
	font-size: 0.9rem;
}

@media only screen and (max-width: 47.999em) {
	/* line 2465, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.resultssort-container .m-results-sort .selectboxit-container span,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options a,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options button {
		font-size: 0.9rem;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 2465, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.resultssort-container .m-results-sort .selectboxit-container span,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options a,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options button {
		font-size: 0.9rem;
	}
}

@media only screen and (min-width: 80em) {
	/* line 2465, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.resultssort-container .m-results-sort .selectboxit-container span,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options a,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options button {
		font-size: 0.9rem;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 2465, scss/80-themes/Saunders/60-components/listing-item-base.scss */
	.resultssort-container .m-results-sort .selectboxit-container span,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options a,
	.resultssort-container .m-results-sort .selectboxit-container .selectboxit-options button {
		height: 29px;
		line-height: 29px;
	}
}

/*# sourceMappingURL=../../../../true */